import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cvDownloadPdf from '../alin_sofianu_cv.pdf';

import './download-resume.css';

class DownloadResume extends Component {
  constructor(props) {
    super(props);
    this.notify = this.notify.bind(this);
  }

  notify() {
    toast.success('Thank you! 🫵', {
      position: 'bottom-center',
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
      className: 'toastify',
    });
  }

  render() {
    return (
      <div className="download">
        <a
          style={{ width: 'fit-content', display: 'block' }}
          download
          href={cvDownloadPdf}
          onClick={this.notify}
        >
          <button type="button" class="buttonnnn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="26"
              viewBox="0 -960 960 960"
              width="26"
              fill="white"
              style={{ marginRight: '10px' }}
              className="buttonnnn__icon"
            >
              <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" />
            </svg>

            <span class="buttonnnn__text">Download CV</span>
          </button>
        </a>
        <ToastContainer />
      </div>
    );
  }
}

export default DownloadResume;
