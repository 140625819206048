import React, { Component } from "react";
import { Parallax } from "react-parallax";
import DownloadResume from "./DownloadResume";

class About extends Component {
  render() {
    if (this.props.data) {
      var aboutMe = this.props.data.aboutMe;
      var contactDetails = this.props.data.contactDetails;
      var name2 = this.props.data.name2;
      var bio = this.props.data.bio;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var phone = this.props.data.phone;
      var email = this.props.data.email;
    }

    return (
      <Parallax
        bgImage={"../images/computer-parallax.svg"}
        bgImageStyle={{
          width: "26%",
          margin: "175px 26%",
        }}
        strength={260}
        style={
          this.props.isDarkTheme
            ? { background: "#ebeeee url(../images/noise-bg-light.svg)" }
            : { background: "#101a28 url(../images/noise-bg-dark.svg)" }
        }
      >
        <div>
          <section id="about">
            <div className="row">
              <div className="columns" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <figure className="speaker-picture">
                  <img src="../../images/profile-pic-noBg2.png" />
                </figure>
                <img className="spinner-outline" src="../../images/spinner-outline.svg" />
                <div style={{ marginBottom: "3rem" }}>
                  <h4
                    className={
                      this.props.isDarkTheme
                      ? "padded-multi-line-light"
                      : "padded-multi-line-dark"
                    }
                    style={
                      this.props.isDarkTheme
                        ? {
                            color: "black",
                            textAlign: "center",
                            letterSpacing: "-1px",
                            display: "flex",
                            justifyContent: "center"
                          }
                        : {
                            color: "white",
                            textAlign: "center",
                            letterSpacing: "-1px",
                            display: "flex",
                            justifyContent: "center"
                          }
                    }
                  >
                    Front-End Developer
                  </h4>
                  <br/>
                  <h6
                    style={
                      this.props.isDarkTheme
                        ? {
                            color: "black",
                            textAlign: "center",
                            letterSpacing: "2px",
                            fontSize: "17px",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "-30px",
                          }
                        : {
                            color: "white",
                            textAlign: "center",
                            letterSpacing: "2px",
                            fontSize: "17px",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "-30px",
                          }
                    }
                    className={
                      this.props.isDarkTheme
                      ? "padded-multi-line-light"
                      : "padded-multi-line-dark"
                    }
                  >
                    Alin Sofianu
                  </h6>
                </div>
              </div>

              <div className="nine columns main-col">
                <DownloadResume />
                <h2
                  style={
                    this.props.isDarkTheme
                      ? {
                          color: "#111827",
                          fontFamily: "JetBrains Mono, monospace",
                          fontWeight: "bold",
                        }
                      : {
                          color: "#fff",
                          fontFamily: "JetBrains Mono, monospace",
                          fontWeight: "bold",
                        }
                  }
                  class={
                    this.props.isDarkTheme
                      ? "padded-multi-line-light"
                      : "padded-multi-line-dark"
                  }
                >
                  {aboutMe}
                </h2>

                <p
                  style={
                    this.props.isDarkTheme
                      ? { color: "#111827" }
                      : { color: "#fff" }
                  }
                >
                  <span
                    class={
                      this.props.isDarkTheme
                        ? "padded-multi-line-light"
                        : "padded-multi-line-dark"
                    }
                  >
                    {bio}
                  </span>
                </p>
                <div className="row">
                  <div className="columns contact-details">
                    <h2
                      style={
                        this.props.isDarkTheme
                          ? {
                              color: "#111827",
                              fontFamily: "JetBrains Mono, monospace",
                              fontWeight: "bold",
                            }
                          : {
                              color: "#fff",
                              fontFamily: "JetBrains Mono, monospace",
                              fontWeight: "bold",
                            }
                      }
                      class={
                        this.props.isDarkTheme
                          ? "padded-multi-line-light"
                          : "padded-multi-line-dark"
                      }
                    >
                      {contactDetails}
                    </h2>
                    <p
                      className="address"
                      style={
                        this.props.isDarkTheme
                          ? { color: "#111827" }
                          : { color: "#fff" }
                      }
                    >
                      <span
                        class={
                          this.props.isDarkTheme
                            ? "padded-multi-line-light"
                            : "padded-multi-line-dark"
                        }
                      >
                        {name2}
                      </span>
                      <br />
                      <span
                        class={
                          this.props.isDarkTheme
                            ? "padded-multi-line-light"
                            : "padded-multi-line-dark"
                        }
                      >
                        {city}, {state}
                      </span>
                      <br />
                      <span
                        class={
                          this.props.isDarkTheme
                            ? "padded-multi-line-light"
                            : "padded-multi-line-dark"
                        }
                      >
                        {phone}
                      </span>
                      <br />
                      <span
                        class={
                          this.props.isDarkTheme
                            ? "padded-multi-line-light"
                            : "padded-multi-line-dark"
                        }
                      >
                        {email}
                      </span>
                    </p>
                  </div>                  
                </div>
              </div>
            </div>
            <p
              className="scrolldown "
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "42px",
                marginTop: "32px",
              }}
            >
              <a
                className="smoothscroll fixxxxx"
                href="#resume"
                aria-label="Scroll to resume section"
              >
                <i className="icon-down-circle">                  
                </i>
              </a>
            </p>
          </section>
        </div>
      </Parallax>
    );
  }
}

export default About;
